$color: #1a2b63;

@font-face {
    font-family: 'Rubik';
    font-weight: 400;
    font-style: normal;
    src: url('../../public/fonts/Rubik-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Rubik';
    font-weight: 300;
    font-style: normal;
    src: url('../../public/fonts/Rubik-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Rubik';
    font-weight: 500;
    font-style: normal;
    src: url('../../public/fonts/Rubik-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Rubik';
    font-weight: 600;
    font-style: normal;
    src: url('../../public/fonts/Rubik-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Rubik';
    font-weight: 700;
    font-style: normal;
    src: url('../../public/fonts/Rubik-Bold.ttf') format('truetype');
}

body {
    margin: 0;
    background-color: $color;
    font-family: 'Rubik';
    font-weight: 400;
    color: white;
}

.home, .nomination, .notfound {
    height: 100vh;
    overflow-y: hidden;

    ::-webkit-scrollbar {
        width: 0px;
    }
}

a {
    color: inherit;
    text-decoration: none !important;
}

.button {
    display: inline-block;
    background-color: white;
    color: $color;
    font-weight: 600;
    font-size: 18px;
    padding: 10px 80px;
    border-radius: 12px;
    border: none !important;
    cursor: pointer;
}

.disabled {
    opacity: 40%;
}

.back {
    position: absolute;
    left: 30px;
    top: 30px;

    img {
        width: 40px;
    }
}

input {
    background: none;
    border: 1px solid white;
    border-radius: 8px;
    width: 400px;
    max-width: 60%;
    margin-top: 20px;
    color: white;
    font-family: 'Rubik';
    padding: 20px 10px;
    font-size: 18px;
}

.reu {
    position: absolute;
    right: 20px;
    top: 20px;

    img {
        width: 90px;
    }
}

.arrow {
    background-color: rgba(0,0,0,0.2);
    border: none !important;
    color: white;

    img {
        width: 20px;
    }
}

.welcome {
    height: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .row {
        width: auto;

        .container {
            text-transform: uppercase;
            text-align: center;


            .icon img {
                width: 100px;
                margin-bottom: 50px;
            }
            .title {
                width: 800px;
                max-width: 70vw;
                margin: auto;
                font-size: 28px;
                line-height: 1.4;

                span {
                    font-weight: 600;
                }
            }
            .ongoing {
                margin-top: 50px;
                margin-bottom: -50px;
            }
            .btn {
                margin-top: 100px;
            }
            .done {
                margin-top: 30px;
                margin-bottom: 30px;
            }
            .writeText {
                margin-top: 10px;
                font-size: 18px;
                text-transform: none;
            }
            .writeBtn {
                margin-top: 30px;
            }
            .durem {
                opacity: 0.7;
                line-height: 1.3;
                text-transform: none;
                margin-top: 30px;
                margin-bottom: -50px;
            }
        }
    }
    
}


.admin {
    text-align: center;

    .icon img {
        width: 100px;
        margin-bottom: 50px;
    }
    .title {
        display: inline-block;
        margin-top: 50px;
        font-size: 28px;
        margin-bottom: 20px;

        span {
            font-weight: 600;
        }
    }
    .loading {
        margin-top: 100px;

        img {
            width: 50px;
        }
    }
    .card {
        text-align: left;
        background-color: rgba(0,0,0,0.2);
        width: 600px;
        max-width: 80%;
        margin: 20px auto;
        border-radius: 20px;
        padding: 20px;

        .info {
            display: inline-block;
            vertical-align: top;
            margin-left: 40px;

            .type {
                font-size: 20px;
                text-transform: uppercase;

                span {
                    font-weight: 600;
                }
            }
            .name {
                margin-top: 20px;
                font-size: 22px;
                font-weight: 600;
            }
            .level {
                margin-top: 10px;
                font-size: 18px;
            }
        }
        .image {
            display: inline-block;
            width: 120px;
            height: 120px;
            border-radius: 20px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
        }
    }
}


.qr {
    text-align: center;

    .icon img {
        width: 100px;
        margin-bottom: 50px;
    }
    .title {
        display: inline-block;
        margin-top: 50px;
        font-size: 28px;
        margin-bottom: 20px;

        span {
            font-weight: 600;
        }
    }
    .loading {
        margin-top: 100px;

        img {
            width: 50px;
        }
    }
    .card {
        text-align: left;
        background-color: rgba(0,0,0,0.2);
        width: 600px;
        max-width: 80%;
        margin: 20px auto;
        border-radius: 20px;
        padding: 20px;
        text-align: center;

        .name {
            margin-top: 20px;
            font-size: 22px;
            font-weight: 600;
        }
        .level {
            margin-top: 10px;
            font-size: 18px;
        }
        .code {
            font-size: 30px;
            font-weight: 600;
        }
        .link {
            font-size: 20px;
            font-weight: 500;
            margin-top: 20px;
        }
        .qrcode {
            display: inline-block;
            background-color: white;
            padding: 20px;
            margin: 40px auto;
            border-radius: 20px;

            svg {
                width: 200px;
            }
        }
    }
}


.profile {
    text-align: center;

    .icon img {
        width: 100px;
        margin-bottom: 50px;
    }
    .title {
        display: inline-block;
        margin-top: 50px;
        font-size: 28px;
        margin-bottom: 20px;

        span {
            font-weight: 600;
        }
    }
    .userCard {
        background-color: rgba(0,0,0,0.2);
        width: 80%;
        margin: 100px auto 0px auto;
        padding: 50px 20px;
        border-radius: 20px;

        .name {
            margin-top: 20px;
            font-size: 30px;
            font-weight: 600;
            margin-bottom: 10px;
            overflow-wrap: anywhere;
        }
        .level {
            font-size: 22px;
            font-weight: 400;
        }
        .type {
            text-transform: uppercase;
            line-height: 1.5;
            margin-top: 40px;
            font-size: 22px;
            font-weight: 400;
            span {
                font-weight: 600;
            }
        }
        .image {
            width: 160px;
            height: 160px;
            border-radius: 50%;
            margin: 0px auto 20px auto;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            position: relative;

            img {
                width: 100px;
                position: absolute;
                left: -50px;
                top: -50px;
                transform: rotate(-45deg);
            }
        }
    }
    .congrats {
        margin-top: 60px;
        font-size: 18px;
        padding: 10px 20px;
        line-height: 1.5;

        span {
            text-transform: uppercase;
            font-weight: 600;
        }
    }
    .messages {
        margin-top: 50px;
        margin-bottom: 100px;

        .card {
            margin: 30px auto;
            width: 80%;
            padding: 20px;
            border-radius: 14px;
            line-height: 1.4;
            background-color: rgba(0,0,0,0.2);
            font-size: 18px;
        }
    }
    .newyear {
        font-size: 24px;
        text-transform: uppercase;
        font-weight: 700;
        margin-bottom: 100px;
    }
    .developed {
        font-size: 18px;
        margin-bottom: 50px;

        a {
            font-weight: 600;
        }
    }
    .loading {
        margin-top: 300px;

        img {
            width: 100px;
        }
    }
}


.write {
    height: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .row {
        width: auto;

        .container {
            text-transform: uppercase;
            text-align: center;


            .icon img {
                width: 100px;
                margin-bottom: 50px;
            }
            .title {
                width: 800px;
                max-width: 70vw;
                margin: auto;
                font-size: 28px;
                line-height: 1.4;

                span {
                    font-weight: 600;
                }
            }
            .image {
                display: inline-block;
                width: 120px;
                height: 120px;
                border-radius: 100px;
                margin-top: -100px;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center center;
            }
            .textTitle {
                font-size: 20px;
                margin: 20px;
                text-transform: none;

                span {
                    font-weight: 600;
                    text-transform: uppercase !important;
                }
            }
            textarea {
                background: none;
                border: 1px solid white;
                border-radius: 8px;
                width: 400px;
                max-width: 90%;
                margin-top: 20px;
                color: white;
                font-family: 'Rubik';
                padding: 10px;
                min-height: 100px;
                font-size: 16px;
            }
            textarea:focus-visible {
                outline: white solid 1px !important;
            }
            textarea:active {
                border: none !important;
            }
            input {
                margin-top: 20px;
            }
            .againButton {
                margin-top: 40px;
            }
        }
    }
    
}

.already {
    height: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .button {
        margin-top: 30px;
    }

    .row {
        width: auto;
        text-align: center;


        .image {
            display: inline-block;
            width: 200px;
            height: 200px;
            border-radius: 8px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
        }
    
        .text {
            font-size: 22px;
            margin-top: 5px;
            font-weight: 400;

            span {
                display: block;
                margin-top: 5px;
                font-weight: 600 !important;
            }
        }

        .writeText {
                margin-top: 10px;
                font-size: 18px;
                text-transform: none;
            }
    }
}

.nominations {
    height: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .row {
        width: auto;

        .container {
            text-transform: uppercase;
            text-align: center;


            .icon img {
                width: 100px;
                margin-bottom: 50px;
            }
            .title {
                width: 800px;
                max-width: 70vw;
                margin: auto;
                font-size: 28px;

                span {
                    display: block;
                    margin-top: 5px;
                    font-weight: 600;
                }
            }
            .btn {
                margin-top: 20px;
            }

            

            .people {
                margin-top: 40px;
                
                width: 100vw;

                .singleUser {
                    border-radius: 10px;
                    margin: 20px 50px;
                    cursor: pointer;
                    transition: 0.2s;
                    padding: 10px 10px 20px 10px;
                    
                    .image {
                        display: inline-block;
                        width: 200px;
                        height: 200px;
                        border-radius: 8px;
                        background-size: cover;
                        background-repeat: no-repeat;
                        background-position: center center;
                    }

                    .name {
                        font-size: 18px;
                        margin-top: 5px;
                        font-weight: 600;
                    }
                    .level {
                        font-size: 14px;
                        margin-top: 10px;
                        font-weight: 500;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 500px) {
    ::-webkit-scrollbar {
        -webkit-appearance: none;
    }
    
    .welcome {
        .row {
            .container {
                .icon img {
                    margin-top: -30px;
                }
                .title {
                    font-size: 22px;
                    max-width: 90vw;
                }
                .btn {
                    margin-top: 60px;
                }
                .writeText {
                    width: 80%;
                    margin: 10px auto;
                    font-size: 16px;
                    text-transform: none;
                }
                .durem {
                    width: 80%;
                    margin-left: auto;
                    margin-right: auto;
                    margin-bottom: -20px;
                    font-size: 12px;
                }
            }
        }
    }

    .nomination {
        .row {
            .container {
                .icon img {
                    width: 60px;
                    margin-top: -30px;
                    margin-bottom: 20px;
                }
                .title {
                    font-size: 18px;
                    max-width: 90vw;

                    span {
                        font-size: 20px;
                    }
                }
                .btn {
                    margin-top: 60px;
                }
                .people {
                    .singleUser {
                        .image {
                            width: 160px;
                            height: 160px;
                        }
                    }
                }
            }
        }
    }

    .write {
        .row {
            .container {
                textarea {
                    max-width: 70%;
                    min-height: 200px;
                }
                .textTitle {
                    max-width: 80%;
                    margin: 20px auto;
                    font-size: 18px;
                }
            }
        }
    }

    .back {
        left: 20px;
        top: 30px;

        img {
            width: 30px;
        }
    }

    .home, .nomination, .notfound {
        height: 95vh;
    }

    .ongoing {
        width: 90%;
        margin-right: auto;
        margin-left: auto;
        margin-bottom: -10px !important;
    }

    .admin {
        .title {
            font-size: 28px;
        }
        .loading {
            img {
                width: 100px;
            }
        }
        .card {
            text-align: center;
            padding: 40px 20px;
    
            .info {
                margin-left: 0px;
    
                .type {
                    font-size: 18px;
                    margin-top: 20px;
                }
                .name {
                    margin-top: 20px;
                }
            }
            .image {
                display: block;
                margin: auto;
                width: 120px;
                height: 120px;
                border-radius: 20px;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center center;
            }
        }
    }
}